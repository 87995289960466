[data-simplebar] {
	align-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	position: relative;
}

.simplebar-wrapper {
	height: inherit;
	max-height: inherit;
	max-width: inherit;
	overflow: hidden;
	width: inherit;
}

.simplebar-mask {
	bottom: 0;
	direction: inherit;
	height: auto !important;
	left: 0;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto !important;
	z-index: 0;
}

.simplebar-offset {
	bottom: 0;
	box-sizing: inherit !important;
	direction: inherit !important;
	left: 0 !important;
	margin: 0;
	-webkit-overflow-scrolling: touch;
	padding: 0;
	position: absolute;
	resize: none !important;
	right: 0 !important;
	top: 0;
}

.simplebar-content-wrapper {
	box-sizing: border-box !important;
	direction: inherit;
	display: block;
	height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
	max-height: 100%; /* Needed for vertical scroll to trigger */
	max-width: 100%; /* Not required for horizontal scroll to trigger */
	overflow: auto; /* Scroll on this element otherwise element can't have a padding applied properly */
	padding: 0px !important;
	position: relative;
	scrollbar-width: none;
	visibility: visible;
	width: auto;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
	display: none;
}

.simplebar-content:before,
.simplebar-content:after {
	content: " ";
	display: table;
}

.simplebar-placeholder {
	max-height: 100%;
	max-width: 100%;
	pointer-events: none;
	width: 100%;
}

.simplebar-height-auto-observer-wrapper {
	box-sizing: inherit !important;
	flex-basis: 0;
	flex-grow: inherit;
	flex-shrink: 0;
	float: left;
	height: 100%;
	margin: 0;
	max-height: 1px;
	max-width: 1px;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
	position: relative;
	width: 100%;
	z-index: -1;
}

.simplebar-height-auto-observer {
	box-sizing: inherit;
	display: block;
	height: 1000%;
	left: 0;
	min-height: 1px;
	min-width: 1px;
	opacity: 0;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 1000%;
	z-index: -1;
}

.simplebar-track {
	bottom: 0;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	right: 0;
	z-index: 1;
}

[data-simplebar].simplebar-dragging .simplebar-content {
	pointer-events: none;
	-webkit-user-select: none;
	user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
	pointer-events: all;
}

.simplebar-scrollbar {
	min-height: 10px;
	position: absolute;
	right: 2px;
	width: 4px;
}

.simplebar-scrollbar:before {
	background: #a2adb7;
	border-radius: 7px;
	content: "";
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
	/* When hovered, remove all transitions from drag handle */
	opacity: 0.5;
	transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
	top: 0;
	width: 11px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
	bottom: 2px;
	top: 2px;
}

.simplebar-track.simplebar-horizontal {
	height: 11px;
	left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
	height: 100%;
	left: 2px;
	right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
	height: 7px;
	left: 0;
	min-height: 0;
	min-width: 10px;
	right: auto;
	top: 2px;
	width: auto;
}

/* Rtl support */
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
	left: 0;
	right: auto;
}

.hs-dummy-scrollbar-size {
	direction: rtl;
	height: 500px;
	opacity: 0;
	overflow-x: scroll;
	overflow-y: hidden;
	position: fixed;
	visibility: hidden;
	width: 500px;
}

.simplebar-hide-scrollbar {
	left: 0;
	overflow-y: scroll;
	position: fixed;
	scrollbar-width: none;
	visibility: hidden;
}

.custom-scroll {
	height: 100%;
}
