//
// Extras pages.scss
//

// pricing

.plan-box {
	.plan-btn {
		position: relative;
		
		&::before {
			background: $gray-300;
			content: "";
			height: 2px;
			left: 0px;
			position: absolute;
			right: 0px;
			top: 12px;
			width: 100%;
		}
	}
}

// blog

.blog-play-icon {
	left: 0;
	margin: 0px auto;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}
