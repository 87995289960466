//
// apexcharts.scss
//
.apex-charts {
	min-height: 10px !important;
	
	text {
		fill: $gray-500;
		font-family: $font-family-base !important;
	}
	
	.apexcharts-canvas {
		margin: 0 auto;
	}
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
	font-family: $font-family-base !important;
}

.apexcharts-legend-series {
	font-weight: $fw-medium;
}

.apexcharts-gridline {
	pointer-events: none;
	stroke: $apex-grid-color;
}

.apexcharts-legend-text {
	color: $gray-600 !important;
	font-family: $font-family-base !important;
	font-size: 13px !important;
}

.apexcharts-pie-label {
	fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
	text {
		fill: $gray-500;
		font-family: $font-family-base !important;
	}
}
