//
// authentication.scss
//

// auth 2

.auth-logo {
	.auth-logo-dark {
		display: $display-block;
	}
	
	.auth-logo-light {
		display: $display-none;
	}
}

.auth-body-bg {
	background-color: $card-bg;
}

// authentication full page

.auth-full-bg {
	background-color: rgba($primary, 0.25);
	display: flex;
	
	@media (min-width: 1200px) {
		height: 100vh;
	}
	
	&::before {
		border-radius: 50%;
		content: "";
		height: 300px;
		position: absolute;
		width: 300px;
	}
	
	.bg-overlay {
		background: url("../../../images/bg-auth-overlay.png");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.auth-full-page-content {
	display: flex;
	
	@media (min-width: 1200px) {
		min-height: 100vh;
	}
}

.auth-review-carousel {
	&.owl-theme {
		.owl-dots {
			.owl-dot {
				span {
					background-color: rgba($primary, 0.25);
				}
				
				&.active,
				&:hover {
					span {
						background-color: $primary;
					}
				}
			}
		}
	}
}
