//
// vector-maps.scss
//

.jvectormap-label {
	background: $gray-800;
	border: none;
	color: $gray-100;
	font-family: $font-family-base;
	font-size: $font-size-base;
	padding: 5px 8px;
}
