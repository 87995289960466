//
// _card.scss
//

.card {
	box-shadow: $box-shadow;
	margin-bottom: $grid-gutter-width;
}

.card-drop {
	color: $body-color;
}

.card-title {
	font-size: 15px;
	font-weight: $fw-semibold;
	margin: 0 0 7px 0;
}

.card-title-desc {
	color: $card-title-desc;
	margin-bottom: 24px;
}
