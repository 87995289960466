/* =============
   General
============= */

html {
	min-height: 100%;
	position: relative;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $gray-700;
}

a {
	text-decoration: none !important;
}

label {
	font-weight: 500;
}

// blockquote

.blockquote {
	border-left: 4px solid $gray-300;
	padding: 10px 20px;
}

.blockquote-reverse {
	border-left: 0;
	border-right: 4px solid $gray-300;
	text-align: right;
}
