//
// _footer.scss
//

.footer {
	background-color: $footer-bg;
	bottom: 0;
	color: $footer-color;
	height: $footer-height;
	left: 250px;
	padding: 20px calc(#{$grid-gutter-width} / 2);
	position: absolute;
	right: 0;
}

@media (max-width: 992px) {
	.footer {
		left: 0;
	}
}

// Enlarge menu
.vertical-collapsed {
	.footer {
		left: $sidebar-collapsed-width;
	}
}

body[data-layout="horizontal"] {
	.footer {
		left: 0 !important;
	}
}
