//
// Parsley
//

.error {
	color: $danger;
}

.parsley-error {
	border-color: $danger;
}

.parsley-errors-list {
	display: none;
	margin: 0;
	padding: 0;
	
	&.filled {
		display: block;
	}
	
	> li {
		color: $danger;
		font-size: 12px;
		list-style: none;
		margin-top: 5px;
	}
}
