/* ==============
  Cropperjs
===================*/

.image-crop-preview {
	.img-preview {
		background-color: $gray-300;
		float: left;
		margin-bottom: 0.5rem;
		margin-right: 0.5rem;
		overflow: hidden;
		text-align: center;
		width: 100%;
		
		> img {
			max-width: 100%;
		}
	}
	
	.preview-lg {
		height: 9rem;
		width: 16rem;
	}
	
	.preview-md {
		height: 4.5rem;
		width: 8rem;
	}
	
	.preview-sm {
		height: 2.25rem;
		width: 4rem;
	}
	
	.preview-xs {
		height: 1.125rem;
		margin-right: 0;
		width: 2rem;
	}
}

.img-crop-preview-btns,
.img-crop-preview-toggles {
	.btn {
		.docs-tooltip {
			display: block;
			margin: -0.47rem -0.75rem;
			padding: 0.47rem 0.75rem;
		}
	}
}
