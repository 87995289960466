//
// coming-soon.scss
//

.counter-number {
	display: flex;
	font-size: 32px;
	font-weight: $fw-semibold;
	text-align: center;
	
	span {
		display: block;
		font-size: 16px;
		font-weight: $font-weight-normal;
		padding-top: 5px;
	}
}

.coming-box {
	width: 25%;
}
