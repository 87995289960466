/* Position */
[class*="pos-abs"] {
	position: absolute;
}

.pos {
	position: relative;
	z-index: 1;
	
	&-abs {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	
	&-abs-y {
		top: 50%;
		transform: translate(0, -50%);
	}
	
	&-abs-x {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

/* Image */
[class*="image-"] {
	height: 100%;
	width: 100%;
}

.image {
	&-cover {
		object-fit: cover;
	}
	
	&-contain {
		object-fit: contain;
	}
	
	&-circle {
		border-radius: 100%;
	}
}

/* Cursor */
.cursor {
	&-pointer {
		cursor: pointer !important;
	}
	
	&-default {
		cursor: default !important;
	}
}
