//
// Range slider
//

.irs--square {
	.irs-bar,
	.irs-to,
	.irs-from,
	.irs-single {
		background: $primary !important;
		font-size: 11px;
	}
	
	.irs-to,
	.irs-from,
	.irs-single {
		&:before {
			border-top-color: $primary;
		}
	}
	
	.irs-line {
		background: $gray-300;
		border-color: $gray-300;
	}
	
	.irs-grid-text {
		color: $gray-400;
		font-size: 11px;
	}
	
	.irs-min,
	.irs-max {
		background: $gray-300;
		color: $gray-400;
		font-size: 11px;
	}
	
	.irs-handle {
		background-color: $card-bg !important;
		border: 2px solid $primary;
		height: 12px;
		top: 26px;
		width: 12px;
	}
}
