//
// right-sidebar.scss
//

.right-bar {
	background-color: $card-bg;
	bottom: 0;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
	display: block;
	float: right !important;
	position: fixed;
	right: -($rightbar-width + 10px);
	top: 0;
	transition: all 200ms ease-out;
	width: $rightbar-width;
	z-index: 9999;
	
	.right-bar-toggle {
		background-color: lighten($dark, 7%);
		border-radius: 50%;
		color: $gray-200;
		height: 24px;
		line-height: 24px;
		text-align: center;
		width: 24px;
		
		&:hover {
			background-color: lighten($dark, 10%);
		}
	}
}

// Rightbar overlay
.rightbar-overlay {
	background-color: rgba($dark, 0.55);
	bottom: 0;
	display: none;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: all 0.2s ease-out;
	z-index: 9998;
}

.right-bar-enabled {
	.right-bar {
		right: 0;
	}
	
	.rightbar-overlay {
		display: block;
	}
}

@include media-breakpoint-down(sm) {
	.right-bar {
		overflow: auto;
		
		.slimscroll-menu {
			height: auto !important;
		}
	}
}
