/* Timepicker */
.bootstrap-timepicker-widget {
	table {
		td {
			a {
				color: $input-color;
				
				&:hover {
					background-color: transparent;
					border-color: transparent;
					border-radius: 4px;
					color: $primary;
					text-decoration: none;
				}
			}
			
			input {
				background-color: $input-bg;
				border: 1px solid $border-color;
				border: 0;
				color: $body-color;
				height: 32px;
				width: 32px;
			}
		}
	}
	
	&.dropdown-menu:after {
		border-bottom-color: $gray-200;
	}
	
	&.timepicker-orient-bottom:after {
		border-top-color: $gray-200;
	}
}
