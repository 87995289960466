//
// Form-Upload
//

/* Dropzone */
.dropzone {
	background: $card-bg;
	border: 2px dashed $gray-400;
	border-radius: 6px;
	min-height: 230px;
	
	.dz-message {
		font-size: 24px;
		width: 100%;
	}
}
