//
// timeline.scss
//

/************** Horizontal timeline **************/

.hori-timeline {
	.events {
		.event-list {
			display: block;
			text-align: center;
			
			.event-down-icon {
				position: relative;
				
				&::before {
					border-bottom: 3px dashed $gray-300;
					content: "";
					left: 0;
					position: absolute;
					right: 0;
					top: 16px;
					width: 100%;
				}
				
				.down-arrow-icon {
					background-color: $card-bg;
					padding: 4px;
					position: relative;
				}
			}
			
			&:hover {
				.down-arrow-icon {
					animation: fade-down 1.5s infinite linear;
				}
			}
			
			&.active {
				.down-arrow-icon {
					animation: fade-down 1.5s infinite linear;
					
					&:before {
						content: "\ec4c";
					}
				}
			}
		}
	}
}

/************** vertical timeline **************/

.verti-timeline {
	border-left: 3px dashed $gray-300;
	margin: 0 10px;
	
	.event-list {
		padding: 0px 0px 40px 30px;
		position: relative;
		
		.event-timeline-dot {
			font-size: 16px;
			left: -9px;
			position: absolute;
			top: 0px;
			z-index: 9;
		}
		
		.event-content {
			border: 2px solid $border-color;
			border-radius: 7px;
			position: relative;
		}
		
		&.active {
			.event-timeline-dot {
				color: $primary;
			}
		}
		
		&:last-child {
			padding-bottom: 0px;
		}
	}
}
