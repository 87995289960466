//
// leaflet-maps.scss
//

.leaflet-map {
	height: 300px;
	
	&.leaflet-container {
		z-index: 99;
	}
}
