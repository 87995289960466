/* Flot chart */
.flot-charts-height {
	height: 320px;
}

.flotTip {
	background-color: rgba($dark, 0.9);
	border-radius: 4px;
	box-shadow: $box-shadow;
	color: $gray-100;
	padding: 8px 12px;
	z-index: 100;
}

.legendLabel {
	color: $gray-500;
}
