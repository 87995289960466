//
// Select 2
//

.sr {
	&__control {
		background: #242629 !important;
		border-color: #323a4d !important;
		height: 36px;
		min-height: auto !important;
		width: 180px;
		
		&--is-focused {
			box-shadow: 0 0 0 1px $success !important;
		}
	}
	
	&__single-value {
		color: #fff !important;
	}
	
	&__input {
		color: #fff !important;
	}
	
	&__menu {
		background: #242629 !important;
	}
	
	&__option {
		color: #fff !important;
		
		&--is-focused {
			background: fade-out($success, .6) !important;
		}
		
		&--is-selected {
			background: $success !important;
		}
	}
}

.select2-container {
	.select2-selection--single {
		background-color: $input-bg;
		border: 1px solid $input-border-color;
		height: 38px;
		
		&:focus {
			outline: none;
		}
		
		.select2-selection__rendered {
			color: $input-color;
			line-height: 36px;
			padding-left: 12px;
		}
		
		.select2-selection__arrow {
			height: 34px;
			right: 3px;
			width: 34px;
			
			b {
				border-color: $gray-500 transparent transparent transparent;
				border-width: 6px 6px 0 6px;
			}
		}
		
		.select2-selection__placeholder {
			color: $body-color;
		}
	}
}

.select2-container--open {
	.select2-selection--single {
		.select2-selection__arrow {
			b {
				border-color: transparent transparent $gray-500 transparent !important;
				border-width: 0 6px 6px 6px !important;
			}
		}
	}
}

.select2-container--default {
	.select2-search--dropdown {
		background-color: $dropdown-bg;
		padding: 10px;
		
		.select2-search__field {
			background-color: $input-bg;
			border: 1px solid $input-border-color;
			color: $gray-600;
			outline: none;
		}
	}
	
	.select2-results__option--highlighted[aria-selected] {
		background-color: $primary;
	}
	
	.select2-results__option[aria-selected="true"] {
		background-color: $dropdown-link-active-bg;
		color: $dropdown-link-active-color;
		
		&:hover {
			background-color: $primary;
			color: $white;
		}
	}
}

.select2-results__option {
	padding: 6px 12px;
}

.select2-dropdown {
	background-color: $dropdown-bg;
	border: 1px solid $dropdown-border-color;
	box-shadow: $box-shadow;
}

.select2-search {
	input {
		border: 1px solid $gray-300;
	}
}

.select2-container {
	.select2-selection--multiple {
		background-color: $input-bg;
		border: 1px solid $input-border-color !important;
		min-height: 38px;
		
		.select2-selection__rendered {
			padding: 2px 10px;
		}
		
		.select2-search__field {
			border: 0;
			color: $input-color;
			
			&::placeholder {
				color: $input-color;
			}
		}
		
		.select2-selection__choice {
			background-color: $gray-200;
			border: 1px solid $gray-300;
			border-radius: 1px;
			padding: 0 7px;
		}
	}
}

.select2-container--default {
	&.select2-container--focus {
		.select2-selection--multiple {
			border-color: $gray-400;
		}
	}
	
	.select2-results__group {
		font-weight: $fw-semibold;
	}
}

// ajax select

.select2-result-repository__avatar {
	float: left;
	margin-right: 10px;
	width: 60px;
	
	img {
		border-radius: 2px;
		height: auto;
		width: 100%;
	}
}

.select2-result-repository__statistics {
	margin-top: 7px;
}

.select2-result-repository__forks,
.select2-result-repository__stargazers,
.select2-result-repository__watchers {
	color: $gray-500;
	display: inline-block;
	font-size: 11px;
	margin-right: 1em;
}

.select2-results__option--highlighted {
	.select2-result-repository__forks,
	.select2-result-repository__stargazers,
	.select2-result-repository__watchers {
		color: rgba($white, 0.8);
	}
}

.select2-result-repository__meta {
	overflow: hidden;
}

// templating-select

.img-flag {
	height: 15px;
	margin-right: 7px;
	width: 18px;
}
