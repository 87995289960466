//
// Datepicker
//

.datepicker {
	border: 1px solid $border-color;
	padding: 8px;
	z-index: 999 !important;
	
	table {
		tr {
			th {
				font-weight: 500;
			}
			
			td {
				&.active,
				&.active:hover,
				.active.disabled,
				&.active.disabled:hover,
				&.today,
				&.today:hover,
				&.today.disabled,
				&.today.disabled:hover,
				&.selected,
				&.selected:hover,
				&.selected.disabled,
				&.selected.disabled:hover {
					background-color: $primary !important;
					background-image: none;
					box-shadow: none;
					color: $white !important;
				}
				
				&.day.focused,
				&.day:hover,
				span.focused,
				span:hover {
					background: $gray-200;
				}
				
				&.new,
				&.old,
				span.new,
				span.old {
					color: $gray-500;
					opacity: 0.6;
				}
				
				&.range,
				&.range.disabled,
				&.range.disabled:hover,
				&.range:hover {
					background-color: $gray-300;
				}
			}
		}
	}
}

.table-condensed {
	> thead > tr > th,
	> tbody > tr > td {
		padding: 7px;
	}
}

.bootstrap-datepicker-inline {
	.datepicker-inline {
		display: inline-block;
		width: auto !important;
	}
}

// DATEPICKER

.datepicker-container {
	background-color: $dropdown-bg;
	border: 1px solid $border-color;
	box-shadow: none;
	
	&.datepicker-inline {
		width: 212px;
	}
}

.datepicker-panel {
	> ul {
		> li {
			background-color: $dropdown-bg;
			border-radius: 4px;
			
			&.picked,
			&.picked:hover {
				background-color: rgba($primary, 0.25);
				color: $primary;
			}
			
			&.highlighted,
			&.highlighted:hover,
			&:hover {
				background-color: $primary;
				color: $white;
			}
			
			&.muted,
			&.muted:hover {
				color: $gray-500;
				opacity: 0.6;
			}
		}
		
		&[data-view="week"] {
			> li {
				font-weight: $fw-medium;
			}
			
			> li,
			> li:hover {
				background-color: $dropdown-bg;
			}
		}
	}
}
