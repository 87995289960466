//
// accordion.scss
//

.custom-accordion {
	.accordion-list {
		align-items: center;
		background-color: $gray-300;
		border-radius: 7px;
		color: $body-color;
		display: flex;
		font-weight: $fw-semibold;
		justify-content: space-between;
		padding: 12px 20px;
		
		&.collapsed {
			i.accor-plus-icon {
				&:before {
					content: "\F0415";
				}
			}
		}
		
		.accor-plus-icon {
			background-color: $card-bg;
			border-radius: 50%;
			display: inline-block;
			font-size: 16px;
			height: 24px;
			line-height: 22px;
			text-align: center;
			width: 24px;
		}
	}
	
	a {
		&.collapsed {
			i.accor-down-icon {
				&:before {
					content: "\F0140";
				}
			}
		}
	}
	
	.card-body {
		color: $text-muted;
	}
}
