/* ==============
  Druafula
===================*/

.task-box {
	border: 1px solid $gray-300;
}

.gu-transit {
	background-color: $gray-200 !important;
	border: 1px dashed $gray-600 !important;
}
