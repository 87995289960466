//
// avatar.scss
//

.avatar-xs {
	height: 2rem;
	width: 2rem;
}

.avatar-sm {
	height: 3rem;
	width: 3rem;
}

.avatar-md {
	height: 4.5rem;
	width: 4.5rem;
}

.avatar-lg {
	height: 6rem;
	width: 6rem;
}

.avatar-xl {
	height: 7.5rem;
	width: 7.5rem;
}

.avatar-title {
	align-items: center;
	background-color: $success;
	color: $white;
	display: flex;
	font-weight: $fw-medium;
	height: 100%;
	justify-content: center;
	width: 100%;
}
