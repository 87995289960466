//
// Form Wizard
//

.form-wizard-wrapper {
	label {
		font-size: 14px;
		text-align: right;
	}
}

.wizard {
	
	// step
	.steps {
		> ul {
			display: flex;
			flex-wrap: wrap;
			list-style: none;
			margin-bottom: 0;
			padding-left: 0;
			
			@media (max-width: 1199.98px) {
				flex-direction: column;
			}
			
			> a, > li {
				flex-basis: 0;
				flex-grow: 1;
			}
			
			> li {
				width: 100%;
				
				a {
					background-color: rgba($primary, 0.1);
					color: $gray-700;
					display: block;
					font-weight: $fw-medium;
					
					padding: .5rem 1rem;
				}
			}
			
			.current-info {
				left: -999em;
				position: absolute;
			}
			
		}
		
		.number {
			border: 2px solid $primary;
			border-radius: 50%;
			color: $primary;
			display: inline-block;
			height: 38px;
			line-height: 34px;
			margin-right: .5rem;
			text-align: center;
			width: 38px;
		}
		
		.current {
			a, a:active, a:hover {
				background-color: rgba($primary, 0.2);
				color: $gray-700;
				
				.number {
					background-color: $primary;
					color: $white;
				}
			}
		}
	}
	
	// content
	> .content {
		background-color: transparent;
		border-radius: 0;
		margin-top: 0;
		min-height: 150px;
		padding: 14px;
		
		> .title {
			left: -999em;
			position: absolute;
		}
		
		> .body {
			height: 100%;
			padding: 14px 0 0;
			position: static;
			width: 100%;
		}
	}
	
	// actions
	
	.actions {
		display: block;
		position: relative;
		text-align: right;
		width: 100%;
		
		> ul {
			display: block;
			padding-left: 0;
			text-align: right;
			
			> li {
				display: inline-block;
				margin: 0 0.5em;
				
			}
		}
		
		a, a:active, a:hover {
			background-color: $primary;
			border-radius: 4px;
			color: $white;
			padding: 8px 15px;
		}
		
		.disabled {
			a, a:active, a:hover {
				background-color: $primary;
				color: $white;
				cursor: not-allowed;
				opacity: .65;
			}
		}
	}
	
	// verticl wixard
	&.vertical-wizard {
		display: flex;
		flex-wrap: wrap;
		
		// step
		.steps {
			> ul {
				flex-direction: column;
				
				> li {
					width: 100% !important;
				}
				
			}
		}
		
		.steps, .content, .actions {
			width: 100%;
		}
		
		.steps {
			@media (min-width: 1200px) {
				width: 25%;
			}
		}
		
		.content {
			padding: 24px;
			@media (min-width: 1200px) {
				padding: 12px 24px;
				width: 75%;
			}
			
			> .body {
				padding: 0;
			}
			
		}
	}
}
