//
// _progress.scss
//

// Progress height small
.progress-sm {
	height: 5px;
}

// Progress height medium
.progress-md {
	height: 8px;
}

// Progress height large
.progress-lg {
	height: 12px;
}

// Progress height Extra large
.progress-xl {
	height: 16px;
}

.custom-progess {
	position: relative;
	
	.progress-icon {
		position: absolute;
		top: -12px;
		
		.avatar-title {
			background: $card-bg;
		}
	}
}

// animated-progess

.animated-progess {
	position: relative;
	
	.progress-bar {
		animation: animate-positive 2s;
		border-radius: 6px;
		position: relative;
	}
}

@keyframes animate-positive {
	0% {
		width: 0;
	}
}
