//
// sparkline.scss
//

.jqstooltip {
	background-color: $gray-800 !important;
	border-color: $gray-900 !important;
	border-radius: 3px;
	box-shadow: $box-shadow-lg;
	box-sizing: content-box;
	height: auto !important;
	padding: 5px 10px !important;
	width: auto !important;
}

.jqsfield {
	color: $gray-200 !important;
	font-family: $font-family-base !important;
	font-size: 12px !important;
	font-weight: $fw-medium !important;
	line-height: 18px !important;
}
