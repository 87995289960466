//
// Chat.scss
//

.chat-leftsidebar {
	@media (min-width: 992px) {
		min-width: 380px;
	}
	
	.chat-leftsidebar-nav {
		.nav {
			background-color: $card-bg;
		}
		
		.tab-content {
			min-height: 488px;
		}
	}
}

.chat-noti-dropdown {
	&.active {
		&:before {
			background-color: $danger;
			border-radius: 50%;
			content: "";
			height: 8px;
			position: absolute;
			right: 0;
			width: 8px;
		}
	}
	
	.btn {
		box-shadow: none;
		font-size: 20px;
		padding: 6px;
	}
}

.chat-search-box {
	.form-control {
		border: 0;
	}
}

.chat-list {
	margin: 0;
	
	li {
		&.active {
			a {
				background-color: $card-bg;
				border-color: transparent;
				box-shadow: $box-shadow;
			}
		}
		
		a {
			border-radius: 4px;
			border-top: 1px solid $border-color;
			color: $gray-600;
			display: block;
			padding: 14px 16px;
			transition: all 0.4s;
			
			&:hover {
				background-color: $card-bg;
				border-color: transparent;
				box-shadow: $box-shadow;
			}
		}
	}
}

.user-chat-nav {
	.dropdown {
		.nav-btn {
			background-color: $light;
			border-radius: 50%;
			box-shadow: none;
			font-size: 16px;
			height: 40px;
			line-height: 40px;
			padding: 0;
			width: 40px;
		}
		
		.dropdown-menu {
			border: 1px solid $border-color;
			box-shadow: $box-shadow;
		}
	}
}

.chat-conversation {
	li {
		clear: both;
	}
	
	.chat-day-title {
		margin-bottom: 24px;
		position: relative;
		text-align: center;
		
		.title {
			background-color: $card-bg;
			padding: 6px 24px;
			position: relative;
			z-index: 1;
		}
		
		&:before {
			background-color: $border-color;
			content: "";
			height: 1px;
			left: 0;
			position: absolute;
			right: 0;
			top: 10px;
			width: 100%;
		}
		
		.badge {
			font-size: 12px;
		}
	}
	
	.conversation-list {
		display: inline-block;
		margin-bottom: 24px;
		position: relative;
		
		.ctext-wrap {
			background-color: rgba($primary, 0.1);
			border-radius: 8px 8px 8px 0px;
			overflow: hidden;
			padding: 12px 24px;
			
			.conversation-name {
				color: $primary;
				font-weight: $fw-semibold;
				margin-bottom: 4px;
			}
		}
		
		.dropdown {
			float: right;
			
			.dropdown-toggle {
				color: $gray-600;
				font-size: 18px;
				padding: 4px;
				@media (max-width: 575.98px) {
					display: none;
				}
			}
			
			.dropdown-menu {
				border: 1px solid $border-color;
				box-shadow: $box-shadow;
			}
		}
		
		.chat-time {
			font-size: 12px;
		}
	}
	
	.right {
		.conversation-list {
			float: right;
			
			.ctext-wrap {
				background-color: $light;
				border-radius: 8px 8px 0px 8px;
				text-align: right;
			}
			
			.dropdown {
				float: left;
			}
			
			&.last-chat {
				.conversation-list {
					&:before {
						left: auto;
						right: 0;
					}
				}
			}
		}
	}
	
	.last-chat {
		.conversation-list {
			&:before {
				bottom: 0;
				color: $primary;
				content: "\F0009";
				font-family: "Material Design Icons";
				font-size: 16px;
				position: absolute;
				right: 0;
				
				@media (max-width: 575.98px) {
					display: none;
				}
			}
		}
	}
}

.chat-input-section {
	border-top: 1px solid $border-color;
}

.chat-input {
	background-color: $light !important;
	border-color: $light !important;
	border-radius: 30px;
	padding-right: 120px;
}

.chat-input-links {
	position: absolute;
	right: 16px;
	top: 50%;
	transform: translateY(-50%);
	
	li {
		a {
			display: inline-block;
			font-size: 16px;
			line-height: 36px;
			padding: 0px 4px;
		}
	}
}

.chat-send {
	@media (max-width: 575.98px) {
		min-width: auto;
	}
}
