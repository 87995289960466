//
// File manager.scss
//

// file manager

.filemanager-sidebar {
	@media (min-width: 1200px) {
		max-width: 230px;
		min-width: 230px;
	}
	
	@media (min-width: 1366px) {
		max-width: 280px;
		min-width: 280px;
	}
}

.categories-list {
	padding: 4px 0;
	
	li {
		a {
			color: $body-color;
			display: block;
			font-weight: $fw-medium;
			padding: 8px 12px;
		}
		
		&.active {
			a {
				color: $primary;
			}
		}
		
		ul {
			padding-left: 16px;
			
			li {
				a {
					color: $text-muted;
					font-size: 13px;
					font-weight: $font-weight-normal;
					padding: 4px 12px;
				}
			}
		}
	}
}
