//
//  Sweetalert2
//

/* CSS Switch */
input[switch] {
	display: none;
	
	+ label {
		background-color: $gray-400;
		background-image: none;
		border-radius: 2rem;
		cursor: pointer;
		display: inline-block;
		font-size: 1em;
		font-weight: $fw-medium;
		height: 24px;
		line-height: 1;
		padding: 0.16667rem;
		position: relative;
		text-align: center;
		transition: all 0.1s ease-in-out;
		width: 56px;
		
		&:before {
			color: $dark;
			content: attr(data-off-label);
			display: block;
			font-family: inherit;
			font-size: 12px;
			font-weight: 500;
			line-height: 21px;
			margin: 3px;
			min-width: 1.66667rem;
			overflow: hidden;
			position: absolute;
			right: 1px;
			text-align: center;
			top: -2px;
			transition: all 0.1s ease-in-out;
		}
		
		&:after {
			background-color: $gray-200;
			border-radius: 2rem;
			box-shadow: none;
			content: "";
			height: 20px;
			left: 3px;
			position: absolute;
			top: 2px;
			transition: all 0.1s ease-in-out;
			width: 20px;
		}
	}
	
	&:checked + label {
		background-color: $primary;
	}
}

input[switch]:checked + label {
	background-color: $primary;
	
	&:before {
		color: $white;
		content: attr(data-on-label);
		left: 3px;
		right: auto;
	}
	
	&:after {
		background-color: $gray-200;
		left: 33px;
	}
}

input[switch="bool"] + label {
	background-color: $danger;
}

input[switch="bool"] + label:before,
input[switch="bool"]:checked + label:before,
input[switch="default"]:checked + label:before {
	color: $white;
}

input[switch="bool"]:checked + label {
	background-color: $success;
}

input[switch="default"]:checked + label {
	background-color: #a2a2a2;
}

input[switch="primary"]:checked + label {
	background-color: $primary;
}

input[switch="success"]:checked + label {
	background-color: $success;
}

input[switch="info"]:checked + label {
	background-color: $info;
}

input[switch="warning"]:checked + label {
	background-color: $warning;
}

input[switch="danger"]:checked + label {
	background-color: $danger;
}

input[switch="dark"]:checked + label {
	background-color: $dark;
}

.square-switch {
	margin-right: 7px;
	
	input[switch] + label,
	input[switch] + label:after {
		border-radius: 4px;
	}
}
