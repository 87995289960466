//
// _horizontal.scss
//

.topnav {
	background: $topnav-bg;
	box-shadow: $box-shadow;
	left: 0;
	margin-top: $header-height;
	padding: 0 calc(#{$grid-gutter-width} / 2);
	position: fixed;
	right: 0;
	z-index: 100;
	
	.topnav-menu {
		margin: 0;
		padding: 0;
	}
	
	.navbar-nav {
		.nav-link {
			color: $menu-item-color;
			font-size: 14px;
			padding: 1rem 1.3rem;
			position: relative;
			
			i {
				font-size: 15px;
			}
			
			&:focus,
			&:hover {
				background-color: transparent;
				color: $menu-item-active-color;
			}
		}
		
		.dropdown-item {
			color: $menu-item-color;
			
			&.active,
			&:hover {
				color: $menu-item-active-color;
			}
		}
		
		.nav-item {
			.nav-link.active {
				color: $menu-item-active-color;
			}
		}
		
		.dropdown {
			&.active {
				> a {
					background-color: transparent;
					color: $menu-item-active-color;
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	body[data-layout="horizontal"] {
		.container-fluid,
		.navbar-header {
			max-width: 85%;
		}
	}
}

@include media-breakpoint-up(lg) {
	.topnav {
		.navbar-nav {
			.nav-item {
				&:first-of-type {
					.nav-link {
						padding-left: 0;
					}
				}
			}
		}
		
		.dropdown-item {
			min-width: 180px;
			padding: 0.5rem 1.5rem;
		}
		
		.dropdown {
			&.mega-dropdown {
				// position: static;
				.mega-dropdown-menu {
					left: 0px;
					right: auto;
				}
			}
			
			.dropdown-menu {
				border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
				margin-top: 0;
				
				.arrow-down {
					&::after {
						position: absolute;
						right: 15px;
						transform: rotate(-135deg) translateY(-50%);
					}
				}
				
				.dropdown {
					.dropdown-menu {
						display: none;
						left: 100%;
						position: absolute;
						top: 0 !important;
					}
				}
			}
			
			&:hover {
				> .dropdown-menu {
					display: block;
				}
			}
		}
		
		.dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
			display: block;
		}
	}
	
	.navbar-toggle {
		display: none;
	}
}

.arrow-down {
	display: inline-block;
	
	&:after {
		border-color: initial;
		border-style: solid;
		border-width: 0 0 1px 1px;
		content: "";
		display: inline-block;
		height: 0.4em;
		margin-left: 10px;
		right: 5px;
		top: 50%;
		transform: rotate(-45deg) translateY(-50%);
		transform-origin: top;
		transition: all 0.3s ease-out;
		width: 0.4em;
	}
}

@include media-breakpoint-down(lg) {
	.topnav-menu {
		.navbar-nav {
			li {
				&:last-of-type {
					.dropdown {
						.dropdown-menu {
							left: auto;
							right: 100%;
						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-down(md) {
	.navbar-brand-box {
		.logo-dark {
			display: $display-block;
			
			span.logo-sm {
				display: $display-block;
			}
		}
		
		.logo-light {
			display: $display-none;
		}
	}
	
	.topnav {
		max-height: 360px;
		overflow-y: auto;
		padding: 0;
		
		.navbar-nav {
			.nav-link {
				padding: 0.75rem 1.1rem;
			}
		}
		
		.dropdown {
			.dropdown-menu {
				background-color: transparent;
				border: none;
				box-shadow: none;
				padding-left: 15px;
				
				&.dropdown-mega-menu-xl {
					width: auto;
					
					.row {
						margin: 0px;
					}
				}
			}
			
			.dropdown-item {
				background-color: transparent;
				position: relative;
				
				&.active,
				&:active {
					color: $primary;
				}
			}
		}
		
		.arrow-down {
			&::after {
				position: absolute;
				right: 15px;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	body[data-layout="horizontal"][data-topbar="light"] {
		.navbar-brand-box {
			.logo-dark {
				display: $display-block;
			}
			
			.logo-light {
				display: $display-none;
			}
		}
		
		.topnav {
			background-color: $primary;
			
			.navbar-nav {
				.nav-link {
					color: rgba($white, 0.6);
					
					&:focus,
					&:hover {
						color: rgba($white, 0.9);
					}
				}
				
				> .dropdown {
					&.active {
						> a {
							color: rgba($white, 0.9) !important;
						}
					}
				}
			}
		}
	}
}

// Colored Topbar

body[data-layout="horizontal"][data-topbar="colored"] {
	#page-topbar {
		background-color: $primary;
		box-shadow: none;
	}
	
	.logo-dark {
		display: none;
	}
	
	.logo-light {
		display: block;
	}
	
	.app-search {
		.form-control {
			background-color: rgba($topbar-search-bg, 0.07);
			color: $white;
		}
		
		span,
		input.form-control::-webkit-input-placeholder {
			color: rgba($white, 0.5);
		}
	}
	
	.header-item {
		color: $header-dark-item-color;
		
		&:hover {
			color: $header-dark-item-color;
		}
	}
	
	.navbar-header {
		.dropdown.show {
			.header-item {
				background-color: rgba($white, 0.1);
			}
		}
		
		.waves-effect .waves-ripple {
			background: rgba($white, 0.4);
		}
	}
	
	.noti-icon {
		i {
			color: $header-dark-item-color;
		}
	}
	
	@include media-breakpoint-up(lg) {
		.topnav {
			background-color: $primary;
			
			.navbar-nav {
				.nav-link {
					color: rgba($white, 0.6);
					
					&:focus,
					&:hover {
						color: rgba($white, 0.9);
					}
				}
				
				> .dropdown {
					&.active {
						> a {
							color: rgba($white, 0.9) !important;
						}
					}
				}
			}
		}
	}
}
