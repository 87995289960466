//
// _demos.scss
//

// Demo Only
.button-items {
	margin-bottom: -12px;
	margin-left: -8px;
	
	.btn {
		margin-bottom: 12px;
		margin-left: 8px;
	}
}

// Lightbox

.mfp-popup-form {
	max-width: 1140px;
}

// Modals

.bs-example-modal {
	bottom: auto;
	display: block;
	left: auto;
	position: relative;
	right: auto;
	top: auto;
	z-index: 1;
}

// Icon demo ( Demo only )
.icon-demo-content {
	color: $gray-500;
	text-align: center;
	
	i {
		color: $gray-600;
		display: block;
		font-size: 24px;
		margin-bottom: 16px;
		transition: all 0.4s;
	}
	
	.col-lg-4 {
		margin-top: 24px;
		
		&:hover {
			i {
				color: $primary;
				transform: scale(1.5);
			}
		}
	}
}

// Grid

.grid-structure {
	.grid-container {
		background-color: $gray-100;
		font-size: 0.8rem;
		font-weight: $fw-medium;
		margin-top: 10px;
		padding: 10px 20px;
	}
}

// card radio

.card-radio {
	background-color: $card-bg;
	border: 2px solid $card-border-color;
	border-radius: $border-radius;
	overflow: hidden;
	padding: 1rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	
	&:hover {
		cursor: pointer;
	}
}

.card-radio-label {
	display: block;
}

.card-radio-input {
	display: none;
	
	&:checked + .card-radio {
		border-color: $primary !important;
	}
}

.navs-carousel {
	.owl-nav {
		margin-top: 16px;
		
		button {
			background-color: rgba($primary, 0.25) !important;
			border-radius: 50% !important;
			color: $primary !important;
			font-size: 20px !important;
			height: 30px;
			line-height: 28px !important;
			margin: 4px 8px !important;
			width: 30px;
		}
	}
}
