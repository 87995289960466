@import "./custom/plugins/icons";
@import "./custom/common";

//Fonts
@import "./custom/fonts/fonts";

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "variables-dark";
// Dark Theme
//@import "./app-rtl.scss"; // RTL
//@import "variables"; // Light Theme

@import "~bootstrap/scss/bootstrap";

@import "./common";

#sidebar-menu {
	ul {
		li {
			a {
				i {
					min-width: 2rem;
				}
			}
		}
	}
}

.noti-icon .badge {
	left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
	float: right;
}

.rdw-editor-main {
	border: 1px solid $gray-300;
	height: 239px;
}

.dz-message {
	padding: 30px;
	text-align: center;
}

// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
	background-color: $card-bg !important;
	margin: 0 20px 0 0 !important;
}

.task-box {
	border: 1px solid $border-color;
}

.react-datepicker-wrapper {
	width: 100% !important;
}

.ReactModal__Overlay {
	z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
	margin-right: 15px;
}

.external-event {
	&:hover {
		cursor: pointer;
	}
}

a:hover {
	cursor: pointer;
}

.rating-container {
	background-color: transparent !important;
}

.input-group-append {
	z-index: 0;
}

.input-color {
	color: $input-color !important;
}

.sketch-picker {
	position: absolute;
	z-index: 1;
}

.rangeslider__fill {
	background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
	background: $primary !important;
}

.flatpickr-weekdays {
	background: $primary !important;
}

span.flatpickr-weekday {
	background: $primary !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
	background: $primary !important;
}

span.flatpickr-weekday {
	color: $white !important;
}

.flatpickr-day.selected {
	background: $primary !important;
}

//File-manager
.file-manager {
	.table td {
		padding: 0.35rem;
	}
}

//Authenication carousel
.slider_css {
	.slide {
		background: none !important;
	}
	
	// .control-dots {
	//   margin-top: 35px !important;
	// }
	
	.carousel-status,
	.control-next,
	.control-prev {
		display: none !important;
	}
}

.carousel {
	.control-dots {
		margin: -5px 0px;
		padding-top: 10px;
		position: relative;
	}
	
	.dot {
		background: #556ee6 !important;
		height: 9px !important;
		width: 9px !important;
	}
}
