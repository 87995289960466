//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
	.page-link {
		border: none;
		border-radius: 30px !important;
		height: 32px;
		line-height: 32px;
		margin: 0 3px;
		padding: 0;
		text-align: center;
		width: 32px;
	}
}
